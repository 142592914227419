import React from 'react';
import { css, keyframes } from '@emotion/react';
import { X } from '@mezzoforte/forge-icons';
// common components imports
import { Box } from '../box';
import { Flex } from '../flex';
import { Heading } from '../heading';
import { Button } from '../button';
import {
  useTheme,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ITokens,
} from '../../../theme';
// Notificator component imports
import { NotificatorsService } from './notificator-service';

export type WrapperWidthProps = 'default' | 'large';

export type NotificatorOptionsProps = {
  /**
   * @property {number} timeOut - Optional value in milliseconds to automatically close notificator.
   */
  timeOut?: number | undefined;
  /**
   * @property {number?} delay - Optional value in milliseconds to delay notification visibility.
   */
  delay?: number;
  /**
   * @property {WrapperWidthProps} wrapperVariant - Controls the width ('default' = 350px, 'large' = 550px).
   */
  wrapperVariant?: WrapperWidthProps;
};

export type NotificationTypeProps =
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left';

export interface NotificationProps {
  id: string;
  notificationType?: NotificationTypeProps;
  header?: React.ReactElement | string;
  content?: React.ReactElement | string;
  options?: NotificatorOptionsProps;
  onDismiss?: (id: string) => void;
}

const noteInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const noteInLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
const transitionInOut = keyframes`
  transition: transform .6s ease-in-out;
`;
const transitionIn = keyframes`
  transition: transform .6s ease-in;
`;

const containerPosition = ({ notificatorPosition = 'bottom-right' }) => {
  switch (notificatorPosition) {
    case 'top-right': {
      return css`
        top: 16px;
        right: 16px;
        animation: ${noteInRight} 0.7s;
        ${transitionInOut};
      `;
    }
    case 'top-left': {
      return css`
        top: 16px;
        left: 16px;
        animation: ${noteInLeft} 0.7s;
        ${transitionIn}
      `;
    }
    case 'bottom-left': {
      return css`
        bottom: 16px;
        left: 16px;
        animation: ${noteInLeft} 0.7s;
        ${transitionIn}
      `;
    }
    default: {
      return css`
        bottom: 16px;
        right: 16px;
        animation: ${noteInRight} 0.7s;
        ${transitionInOut};
      `;
    }
  }
};

const Container: React.FC<
  React.PropsWithChildren<{ notificatorPosition: string }>
> = (props) => (
  <Box
    css={css`
      box-sizing: border-box;
      position: fixed;
      z-index: 20;
      ${containerPosition({ notificatorPosition: props.notificatorPosition })}
    `}
    {...props}
  />
);

type WrapperVariantProps = {
  notificatorPosition: NotificationTypeProps;
  variant: WrapperWidthProps;
  forgeTokens: ITokens;
};

const NotificatoWrapper: React.FC<
  React.PropsWithChildren<WrapperVariantProps>
> = ({ forgeTokens, variant, ...props }) => (
  <Box
    padding={forgeTokens.space[3]}
    width={variant === 'large' ? '550px' : '350px'}
    borderRadius={forgeTokens.radii.basic}
    boxShadow={forgeTokens.shadows.openElementsShadow}
    backgroundColor={forgeTokens.colors.background}
    css={css`
      height: 100%;
      transition: 0.3s ease;
      position: relative;
      pointer-events: auto;
      overflow: hidden;
      color: black;
      opacity: 0.95;

      @media screen and (max-width: 600px) {
        width: 350px;
      }
    `}
    {...props}
  />
);

export const notificatorCloseButtonIconOnlyStyles = (forgeTokens: ITokens) =>
  ({
    position: 'absolute',
    top: forgeTokens.space[3],
    right: forgeTokens.space[3],
    padding: forgeTokens.space[1],
    border: 'none',
    color: forgeTokens.colors.text,
    backgroundColor: 'transparent',
  }) as const;

const NotificatorCloseButton = ({
  forgeTokens,
  close,
}: {
  forgeTokens: ITokens;
  close: () => void;
}) => {
  return (
    <Button
      {...notificatorCloseButtonIconOnlyStyles(forgeTokens)}
      onClick={close}
    >
      <X color={forgeTokens.colors.brand} />
    </Button>
  );
};

const DefaultNotificationHeader: React.FC<{
  header?: React.ReactElement | string;
  close?: () => void;
}> = ({ header, close }) => {
  const { forgeTokens } = useTheme();
  return (
    <>
      <Flex
        flex="0 0 80%"
        width="80%"
        paddingTop={forgeTokens.space[1]}
        className="notificator-header-element-container"
      >
        <Heading
          variant="h5"
          as="div"
          marginBottom={4}
          marginRight={2}
        >
          {header}
        </Heading>
      </Flex>
      <Flex
        flex="0 0 20%"
        width="20%"
        className="notificator-header-close-container"
      >
        {close && (
          <NotificatorCloseButton
            forgeTokens={forgeTokens}
            close={close}
          />
        )}
      </Flex>
    </>
  );
};

const NotificatorHeader = ({
  header,
  close,
}: {
  header?: React.ReactElement | string;
  close?: () => void;
}) => {
  const shouldUseDefaultHeader = typeof header === 'string';

  return (
    <Flex
      flexDir="row"
      className="notificator-header-wrapper"
    >
      {shouldUseDefaultHeader ? (
        <DefaultNotificationHeader
          header={header}
          close={close}
        />
      ) : (
        header
      )}
    </Flex>
  );
};

export const Notificator = ({
  id,
  header,
  content,
  notificationType = 'bottom-right',
  options,
  onDismiss,
}: {
  id: string;
  header?: React.ReactElement | string;
  content?: React.ReactElement | string;
  notificationType?: NotificationTypeProps;
  options?: NotificatorOptionsProps;
  onDismiss?: (id: string) => void;
}): JSX.Element => {
  const { forgeTokens } = useTheme();
  const handleNotificationDismiss = () => {
    NotificatorsService.closeNotification(id);
    if (onDismiss) {
      onDismiss(id);
    }
  };

  const wrapper =
    options && options.wrapperVariant ? options.wrapperVariant : 'default';

  return (
    <Container notificatorPosition={notificationType}>
      <NotificatoWrapper
        variant={wrapper}
        notificatorPosition={notificationType}
        forgeTokens={forgeTokens}
      >
        <Box marginBottom={2}>
          <Box>
            <NotificatorHeader
              header={header}
              close={onDismiss && handleNotificationDismiss}
            />
          </Box>
          <Box
            marginBottom={3}
            className="content-wrap"
          >
            <>{content}</>
          </Box>
        </Box>
      </NotificatoWrapper>
    </Container>
  );
};
