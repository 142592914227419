import React from 'react';
import { Radio, type RadioProps } from './radio';

export type RadioToggleProps = RadioProps;

export const RadioToggle = React.forwardRef<HTMLInputElement, RadioProps>(
  (props, ref) => (
    <Radio
      ref={ref}
      variant="button"
      {...props}
    />
  )
);

export const toTesting = <RadioToggle />;
