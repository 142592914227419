import React from 'react';
import { RadioGroup, type RadioGroupProps } from './radio-group';

export type RadioToggleGroupProps = RadioGroupProps;

export const RadioToggleGroup = React.forwardRef<
  HTMLDivElement,
  RadioGroupProps
>((props, ref) => {
  return (
    <RadioGroup
      ref={ref}
      {...props}
      variant="button"
    />
  );
});
