import React, { useId, useState } from 'react';
import {
  chakra,
  FormControl,
  RadioGroup as CRadioGroup,
  RadioGroupProps as CRadioGroupProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { ChildrenWithProps } from '../../../typescript-utils';
import { Legend } from './legend';
import { Text } from '../text';
import { Radio, RadioProps } from './radio';
import { Alert } from '../alert';
import { Box } from '../box';
import { FormHelperText } from './form-helper-text';
import { cartesianProps } from '../../../utils/cartesian-props';

export interface RadioGroupProps extends CRadioGroupProps {
  /**
   * Labeltitle for the radiogroup
   */
  label?: React.ReactNode;
  /**
   * Helper text below the inputs
   */
  helperText?: React.ReactNode;
  /**
   * If `true`, the will indicate an error.
   * This sets `aria-invalid=true`
   *
   */
  isInvalid?: boolean;
  /**
   * Shown when isInvalid
   */
  errorInfo?: React.ReactNode;
  children: ChildrenWithProps<RadioProps>;
  variant?: 'default' | 'button';
}

export const radioGroupTheme = {
  variants: {
    button: {
      container: {
        marginY: 2,
      },
      control: {
        display: 'inline-flex',
        backgroundColor: 'inputBorder',
        border: '1px solid',
        borderColor: 'inputBorder',
        borderRadius: '4px',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
        gap: '1px',
        overflow: 'hidden',
        marginY: 2,
        _invalid: {
          borderColor: 'danger',
          backgroundColor: 'danger',
        },
        label: { borderWidth: 0 },
      },
    },
  },
};

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      label,
      helperText,
      isInvalid,
      errorInfo,
      children,
      id: optionalId,
      ...props
    },
    ref
  ) => {
    const generatedId = useId();
    const [id] = useState(optionalId || generatedId);
    const idString = `forge-radiogroup-${id}`;
    const idErrorString = `${idString}-error`;
    const idHelperString = `${idString}-helper`;

    const styles = useMultiStyleConfig('RadioGroup', props);

    return (
      <chakra.fieldset __css={styles.container}>
        <FormControl
          ref={ref}
          isInvalid={isInvalid}
        >
          {label && (
            <Legend>
              <Text variant="bold">{label}</Text>
            </Legend>
          )}
          <CRadioGroup
            {...props}
            aria-invalid={isInvalid}
            __css={styles.control}
          >
            {children}
          </CRadioGroup>
        </FormControl>
        {isInvalid && errorInfo && (
          <Alert
            variant="danger"
            small
            id={idErrorString}
            display="block"
            marginTop={1}
          >
            {errorInfo}
          </Alert>
        )}
        {helperText && (
          <Box marginTop={1}>
            <FormHelperText id={idHelperString}>{helperText}</FormHelperText>
          </Box>
        )}
      </chakra.fieldset>
    );
  }
);

/**
 * For testing
 */
const components = cartesianProps<RadioProps>(
  {
    value: ['value-1', 'value-2'],
    id: ['testing-id'],
    isDisabled: [true, false],
    isInvalid: [true, false],
    isTruncated: [true, false],
    children: ['Radio'],
    variant: ['default', 'button'],
  },
  Radio,
  (props, index) => (
    <Radio
      key={index}
      defaultChecked={index === 2}
      {...props}
    />
  )
);

export const toTesting = (
  <FormControl>
    <RadioGroup label="Testing label">{components}</RadioGroup>
  </FormControl>
);
