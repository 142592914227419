import React, { useContext, useEffect } from 'react';
import { useBreakpoint } from '../../../hooks/breakpoints';
import { CarouselItem } from './carousel-item';
import { CarouselTrack } from './carousel-track';
import { CarouselSlider } from './carousel-slider';
import {
  CarouselValueContext,
  CarouselDispatchContext,
  CarouselValueContextType,
  CarouselDispatchContextType,
} from './carousel-provider';

type Constraints = [number, number, number];
type Multipliers = [number, number, number];

export interface CarouselProps {
  children: React.ReactNode[];
  /**
   * Control the gap between individual elements.
   */
  gap?: number;
  /**
   * Custom constraint - Array of constraints based on breakpoints.
   *
   * arr[0] = Nbr of items, when breakpoint <= 'md' always 1 item visible.
   *
   * arr[1] = Nbr of items, when breakpoint between 'md' and 'xl'.
   *
   * arr[2] = Nbr of items, when breakpoint >= 'xl'.
   */
  constraints?: Constraints;
  /**
   * Custom multiplier
   * arr[0] = when breakpoint less than 'md' multiplier always 0.65
   *
   * arr[1] = when breakpoint between 'md' and 'xl'
   *
   * arr[2] = when breakpoint greater than 'xl'.
   */
  multipliers?: Multipliers;
  /**
   * When enabled itemWidth is calculated based on given `gap`.
   * @default false - item is full width on sm.
   */
  useGapWithSm?: boolean;
  /**
   * Callback for CarouselItem onClick
   */
  jumpTo?: (value: number) => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  gap = 10,
  constraints = [1, 2, 3],
  multipliers = [0.65, 0.5, 0.35],
  jumpTo,
  useGapWithSm = false,
}) => {
  const ctxValue = useContext(CarouselValueContext);
  const ctxDispatch = useContext(CarouselDispatchContext);
  const { sliderWidth, itemWidth } = ctxValue as CarouselValueContextType;
  const { setItemWidth, setMultiplier, setConstraint, setPositions } =
    ctxDispatch as CarouselDispatchContextType;

  const { breakpoints } = useBreakpoint();

  const [constraint, multiplier, isSmall] = (() => {
    if (breakpoints?.sm) {
      const multiplier = multipliers[0];
      const constraint = constraints[0];
      return [constraint, multiplier, true];
    }
    if (!!breakpoints?.md || !!breakpoints?.lg) {
      const constraint = constraints[1];
      const multiplier = multipliers[1];
      return [constraint, multiplier, false];
    }
    // XL
    const constraint = constraints[2];
    const multiplier = multipliers[2];
    return [constraint, multiplier, false];
  })();

  useEffect(() => {
    if (sliderWidth > 0) {
      setConstraint(constraint);
      setMultiplier(multiplier);
      if (isSmall) {
        const itemGap = useGapWithSm ? gap * 3 : gap;
        const itemWidth =
          constraint > 1
            ? sliderWidth / constraint - itemGap
            : sliderWidth - itemGap;
        setItemWidth(itemWidth);
      }
      setItemWidth(sliderWidth / constraint - gap);
    }
  }, [sliderWidth, constraint, multiplier, isSmall]);

  useEffect(() => {
    if (!!children && itemWidth > 0) {
      const positions = children.map(
        (_, idx) => -Math.abs((itemWidth + gap) * idx)
      );
      setPositions(positions);
    }
  }, [itemWidth]);

  return (
    <CarouselSlider gap={gap}>
      <CarouselTrack>
        {children?.map((child, index) => (
          <CarouselItem
            gap={gap}
            key={index}
            index={index}
            jumpTo={jumpTo}
          >
            {child}
          </CarouselItem>
        ))}
      </CarouselTrack>
    </CarouselSlider>
  );
};
